import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Navigation from "../../../components/navigation/navigation";
import InvestorRelationsNavData from "../../../data/investor-relations/investor-relations-nav-data";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const CompanyHistory = () => {
  const title = "Company History";
  const description =
    "For more than 100 years, WaFd Bank has maintained its commitment to helping to build strong communities.";

  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/about-us/investor-relations/company-history/hero-company-history-04112023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/investor-relations/company-history/hero-company-history-04112023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/investor-relations/company-history/hero-company-history-04112023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/investor-relations/company-history/hero-company-history-04112023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/investor-relations/company-history/hero-company-history-04112023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/investor-relations/company-history/hero-company-history-04112023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/about-us/investor-relations/company-history/hero-company-history-04112023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "company-history-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd Bank sign on a brick wall.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Helping Strengthen Our Communities Since 1917",
            class: "text-white"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      active: true,
      title: "Company History"
    }
  ];

  const SEOData = {
    title: "Company History",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations/company-history"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-company-history-04112023.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...InvestorRelationsNavData} />
      <section className="container">
        <h1>Company History</h1>
        <div className="row">
          <div className="col-md-6">
            <p id="history-paragraph-1">
              On April 13 of 1917, just a week after the United States declared war against Germany, a small group of
              business owners in Ballard - a fishing and lumbering community a few miles north of Seattle - founded what
              was to become WaFd Bank. Their objective was to provide a source of funding for home loans for this
              rapidly growing community, and to help their neighbors build a safe and secure financial future. Back
              then, the average worker was earning just $5 a day so saving up to buy a home could seem a nearly
              impossible goal. Today, over 100 years later, WaFd Bank honors these roots with our mission to strengthen
              communities and the financial well-being of our clients.
            </p>

            <p id="history-paragraph-2">
              WaFd, Inc. (&ldquo;Company&rdquo; or &ldquo;Washington Federal&rdquo;) is a bank holding company
              headquartered in Seattle, Washington that conducts its operations through a federally insured bank
              subsidiary. Its subsidiary is Washington Federal Bank, doing business as WaFd Bank (&ldquo;WaFd
              Bank&rdquo; or &ldquo;Bank&rdquo;), which operates in over 200 offices in eight western states. The
              Company had its origin on April 24, 1917, as Ballard Savings and Loan Association. In 1935, the
              state-chartered Bank converted to a federal charter, became a member of the Federal Home Loan Bank system
              and obtained federal deposit insurance. On November 9, 1982, the Company converted from a federal mutual
              to a federal stock association. In 1995, the stockholders approved a reorganization whereby the Bank
              became a wholly owned subsidiary of a newly formed holding company, WaFd, Inc. Then in 2013, the Bank
              converted from a thrift charter to a national bank charter, and in 2021, switched from a Federal charter
              to a Washington state charter.
            </p>

            <p id="history-paragraph-3">
              WaFd Bank obtains its funds primarily through deposits from the general public, repayments of loans,
              borrowings and retained earnings. These funds are used largely to make loans to individuals and
              businesses, including loans for the purchase of new and existing homes, construction and land loans,
              commercial real estate loans, and commercial and industrial loans.
            </p>
          </div>
          <div className="col-md-6">
            <p id="history-paragraph-4">
              The company grew via acquisitions, starting in 1958, when then Ballard Federal Savings and Loan
              Association merged with Washington Federal Savings and Loan Association of Bothell, and the latter name
              was retained for wider geographical acceptance. Here is a list of additional bank acquisitions:
            </p>

            <ul id="history-list" className="green-60-bullets">
              <li id="history-list-item-1">
                <strong className="text-success">1971</strong> - Seattle Federal Savings and Loan Association.
              </li>
              <li id="history-list-item-2">
                <strong className="text-success">1978</strong> - First Federal Savings and Loan Association of Mount
                Vernon.
              </li>
              <li id="history-list-item-3">
                <strong className="text-success">1987</strong> - United First Federal, Provident Federal Savings and
                Loan, and Northwest Federal Savings and Loan, all headquartered in Boise, Idaho.
              </li>
              <li id="history-list-item-4">
                <strong className="text-success">1988</strong> - Freedom Federal Savings and Loan Association in
                Corvallis, Oregon.
              </li>
              <li id="history-list-item-5">
                <strong className="text-success">1990</strong> - Family Federal Savings in Oregon.
              </li>
              <li id="history-list-item-6">
                <strong className="text-success">1991</strong> - First Federal Savings and Loan Association of Idaho
                Falls, Idaho, and the deposits of First Western Savings Association of Las Vegas, Nevada, in Portland
                and Eugene, Oregon, doing business as Metropolitan Savings Association.
              </li>
              <li id="history-list-item-7">
                <strong className="text-success">1993</strong> - First Federal Savings Bank of Utah.
              </li>
              <li id="history-list-item-8">
                <strong className="text-success">1995</strong> - West Coast Mutual Savings Bank.
              </li>
              <li id="history-list-item-9">
                <strong className="text-success">1996</strong> - Metropolitan Bancorp of Seattle.
              </li>
              <li id="history-list-item-10">
                <strong className="text-success">2003</strong> - United Savings and Loan Bank in Seattle.
              </li>
              <li id="history-list-item-11">
                <strong className="text-success">2007</strong> - First Federal Bank of the Southwest, Inc., the holding
                company for First Federal Bank located in Roswell, New Mexico.
              </li>
              <li id="history-list-item-12">
                <strong className="text-success">2008</strong> - Mutual Bancshares, Inc., the holding company for First
                Mutual Bank in Washington.
              </li>
              <li id="history-list-item-13">
                <strong className="text-success">2010</strong> - Horizon Bank in Bellingham, Washington, from the
                Federal Deposit Insurance Corporation (&ldquo;FDIC&rdquo;), as receiver for Horizon Bank.
              </li>
              <li id="history-list-item-14">
                <strong className="text-success">2011</strong> - 6 branch locations from Charter Bank of New Mexico.
              </li>
              <li id="history-list-item-15">
                <strong className="text-success">2011</strong> - Western National Bank in Phoenix, Arizona in an
                FDIC-assisted transaction.
              </li>
              <li id="history-list-item-16">
                <strong className="text-success">2012</strong> - South Valley Bancorp, Inc., the holding company for
                South Valley Bank &amp; Trust (&ldquo;SVBT&rdquo;) headquartered in Oregon.
              </li>
              <li id="history-list-item-17">
                <strong className="text-success">2014</strong> - 74 branch locations from Bank of America, National
                Association.
              </li>
              <li id="history-list-item-18">
                <strong className="text-success">2024</strong> - Luther Burbank Corporation in California, the holding
                company for Luther Burbank Savings.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CompanyHistory;

